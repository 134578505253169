/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as ComAtprotoServerDefs from './defs'

export interface QueryParams {}

export interface InputSchema {
  identifier: ComAtprotoServerDefs.Identifier
  initial_device_display_name?: string
  user_share_code?: {}
  /** The login type. */
  type: string
  handle?: string
  name?: string
  inviteCode?: string
  /** The login type. */
  tgLoginType?: number
  tgUserData?: string
  inviterHandle?: string
  /** 1: Scan your QR code,2: Invite link,3:NFC */
  invitationType?: 1 | 2 | 3
  [k: string]: unknown
}

/** Login by wallet address returned on successful account creation. */
export interface OutputSchema {
  did: string
  parentDid?: string
  accessJwt: string
  refreshJwt: string
  handle: string
  didDoc?: {}
  walletAddress?: string
  defaultAccountSession?: ComAtprotoServerDefs.AccountInfo[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export class InvalidHandleError extends XRPCError {
  constructor(src: XRPCError) {
    super(src.status, src.error, src.message, src.headers, { cause: src })
  }
}

export class InvalidPasswordError extends XRPCError {
  constructor(src: XRPCError) {
    super(src.status, src.error, src.message, src.headers, { cause: src })
  }
}

export function toKnownErr(e: any) {
  if (e instanceof XRPCError) {
    if (e.error === 'InvalidHandle') return new InvalidHandleError(e)
    if (e.error === 'InvalidPassword') return new InvalidPasswordError(e)
  }

  return e
}
